<template>
    <div class="list custom-analysis-list">
        <div class="item" v-for="(item, index) in list" :key="item.indication + index">
            <div class="select"> 
                <el-select v-model="item.indication" @change="onChange(index)" collapse-tags
                    style="margin-right: 20px;" placeholder="请选择" filterable>
                    <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
                <el-button type="danger" icon="el-icon-minus" @click="del(index)" circle></el-button>
            </div>
            <div v-loading="item.loading">
                <p class="center" v-if="item.tableData1.length !== 0">表 {{index*2 + 8}}：相同适应症/功能主治的产品基本信息</p>
                <table v-if="item.tableData1.length !== 0">
                    <thead>
                        <tr>
                            <th style="width: 50px">序号</th>
                            <th style="width: 150px">通用名</th>
                            <!--<th>企业名称</th>-->
                            <th>功能主治</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(child, i) in item.tableData1" :key="child.comName + i">
                            <td>{{i + 1}}</td>
                            <td>{{child.comName}}</td>
                            <!--<td>{{child.compName}}</td>-->
                            <td>{{child.indication}}</td>
                        </tr>
                        <tr v-if="item.tableData1.length === 0">
                            <td :colspan="4" class="no-data">暂无数据</td>
                        </tr>
                    </tbody>
                </table>
                <p class="center" v-if="item.tableData2.length !== 0">表 {{index*2 + 9}}：历年相同适应症/功能主治的药品历年销售一览表（单位：万元）</p>
                <table v-if="item.tableData2.length !== 0">
                    <thead>
                        <tr>
                            <th style="width: 50px">序号</th>
                            <th style="width: 150px">品种</th>
                            <th style="width: 100px">规格</th>
                            <!-- <th>生产企业</th> -->
                            <th v-for="year in years" :key="year">{{year}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(child, i) in item.tableData2" :key="child.comName + i">
                            <td>{{i + 1}}</td>
                            <td>{{child.comName}}</td>
                            <td>{{child.spec}}</td>
                            <td v-for="year in years" :key="year">{{child[year]}}</td>
                        </tr>
                        <tr v-if="item.tableData2.length === 0">
                            <td :colspan="years.length + 4" class="no-data">暂无数据</td>
                        </tr>
                    </tbody>
                </table> 
            </div>
        </div>
        <el-button type="primary" icon="el-icon-plus" @click="add">添加类</el-button>
    </div>
</template>

<script>
export default {
    props: {
        classApi: {
            type: String,
            default: 'report/drugSysUserReport/queryIndicationListByKeyword'
        },
        api: {
            type: String,
            default: 'report/drugSysUserReport/queryCompetitiveAssay'
        },
        comCode: {
            type: String,
            default: ''
        },
        years: {
            type: Array,
            default: ()=>{
                return []
            }
        }
    },
    data() {
        return {
            options: [],
            list: [
                {
                    indication: '',
                    tableData1: [],
                    tableData2: [],
                    loading: false
                }
            ]
        }
    },
    methods: {
        getClassList() {
            this.$api.get(this.classApi, {comCode: this.comCode}).then(res=>{
                let options = []
                if(res.data && res.data.length !== 0) {
                    res.data.forEach(ele=>{
                        options.push({
                            value: ele.indication,
                            label: ele.indication
                        })
                    })
                }
                this.options = options
            })
        },
        del(index) {
            this.$store.commit('confirm', {
                content: '确定删除吗？',
                callback:(next) => {
                    next()
                    this.list.splice(index, 1)
                }
            })
        },
        add() {
            this.list.push({
                indication: '',
                tableData1: [],
                tableData2: [],
                loading: false
            })
        },
        onChange(index) {
            let indication = this.list[index].indication
            // console.log(this.list[index].indication)
            if(indication === '') {
                this.$store.commit('error', '请选择主治类型')
                return
            }
            this.list[index].loading = true
            this.$api.get(this.api, {indication}).then(res=>{
                // console.log(res)
                this.list[index].tableData1 = res.data.instructionBooks
                this.list[index].tableData2 = res.data.aimDrugsSaleYear
            })
            .finally(()=>{
                this.list[index].loading = false
            })
        },
        loadAllData() {
            this.list.forEach(item => {
                if (item.tableData1.length === 0 && item.tableData2.length === 0) {
                    this.$api.get(this.api, {indication: item.indication}).then(res=>{
                        // console.log(res)
                        item.tableData1 = res.data.instructionBooks
                        item.tableData2 = res.data.aimDrugsSaleYear
                    })
                }
            })
        },
    },
    created() {
        this.getClassList()
        // console.log(this.years)
    }
}
</script>

<style lang="scss">
    @import "~@/assets/css/var";
    .custom-analysis-list{
        .item{
            margin-bottom: 20px;
        }
    }
</style>